<template>
  <div id="NewsList">
    <h1 class="title_font">NEWS LIST</h1>
    <div class="row">
      <div class="news_list">
        <template v-if="news.length <= 0">
          <div class="card">
            <div class="img_box">
              <div class="search-ssc-lg__tag ssc-square"></div>
            </div>
            <div class="content">
              <h4 class="ssc">
                <div class="ssc-head-line w-80"></div>
              </h4>
              <div class="ssc card_content">
                <div class="ssc-line w-60"></div>
                <div class="ssc-line w-40"></div>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="img_box">
              <div class="search-ssc-lg__tag ssc-square"></div>
            </div>
            <div class="content">
              <h4 class="ssc">
                <div class="ssc-head-line w-80"></div>
              </h4>
              <div class="ssc card_content">
                <div class="ssc-line w-60"></div>
                <div class="ssc-line w-40"></div>
              </div>
            </div>
          </div>
        </template>

        <template v-else>
          <template v-for="(item, item_index) in now_news">
            <div class="card" :key="`news_${item.NewsID}`">
              <div class="img_box">
                <img :src="GetFullPath(item.Image)" />
              </div>
              <div class="content">
                <h4 class="content_font">{{ item.Title | titleellipsis }}</h4>
                <div class="card_content sub_content_font">
                  {{ item.Content | ellipsis }}
                </div>
                <router-link
                  :to="`/news/show/${item.NewsID}`"
                  class="sub_content_font"
                  >MORE</router-link
                >
              </div>
            </div>
            <hr
              v-if="item_index != news.length - 1"
              :key="`hr_${item.NewsID}`"
            />
          </template>
        </template>
      </div>
      <div v-if="news.length > 0" class="pager">
        <Pagination :total_page_count="page_count" />
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from '../components/NewsList/Pagination/index';
import { GetMetaData } from '@/common/meta';
import { ReturnErrorPage } from '@/common/prerender_event';
export default {
  name: 'NewsList',
  components: {
    Pagination,
  },
  data() {
    return {
      news: [],
      per_page: 5,
      page_count: 10,
      meta_data: null,
    };
  },
  methods: {
    async LoadVideo() {
      this.$store.commit('SetLoading', true);
      let response = await this.GetData('/news');
      this.$store.commit('SetLoading', false);
      if (response != 'error') {
        this.news = response;
        this.page_count = Math.ceil(response.length / 5);
      }
    },
  },
  watch: {
    now_news() {
      this.now_news.length <= 0 && this.news.length > 0
        ? ReturnErrorPage()
        : '';
    },
  },
  computed: {
    now_page() {
      return parseInt(this.$route.params.page);
    },
    now_news() {
      let tmp_news = [];
      this.news.forEach((item, item_index) => {
        item_index >= this.now_page * this.per_page - this.per_page &&
        item_index < this.now_page * this.per_page
          ? tmp_news.push(item)
          : '';
      });
      return tmp_news;
    },
  },
  created() {
    this.meta_data = GetMetaData('News', '', '');
    this.LoadVideo();
  },
  filters: {
    ellipsis(value) {
      var regEx = /<[^>]*>/g;
      value = value.replace(regEx, '');
      if (!value) return '';
      if (value.length > 50) {
        return value.slice(0, 50) + '...';
      }
      return value;
    },
    titleellipsis(value) {
      if (!value) return '';
      if (value.length > 30) {
        return value.slice(0, 30) + '...';
      }
      return value;
    },
  },
  metaInfo() {
    return this.meta_data;
  },
};
</script>
